@import "/src/styles/mixin";

.social {
  &_item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 12px;
  }

  &_name {
    @include font14;
    min-width: 70px;
  }

  &_button {
    @include font14;
    @include dashedBottomLine;
    color: var(--grey-fourth);

    &::before {
      border-color: var(--grey-fourth);
    }
  }
}
