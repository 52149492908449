.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  height: 100%;

  button {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
  }

  .haed {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .photos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
}
