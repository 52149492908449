@import "/src/styles/mixin";

.marker_wrap {
  position: relative;

  .custom_marker {
    position: absolute;
    z-index: 1;
    left: -10px;
    top: -24px;
    cursor: pointer;
  }
}

.custom_balloon {
  @include font14;
  opacity: 1;
  position: absolute;
  border-radius: 14px;
  padding: 24px 12px;
  bottom: -24px;
  min-width: 273px;
  width: auto;
  max-width: 364px;
  min-height: 176px;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgb(255, 255, 255);
  color: var(--secondary-color);
  transition: 0.25s ease;
  display: flex;
  align-items: flex-start;

  .balloon_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 364px;

    .name {
      @include font14Bold;
      margin-bottom: 6px;
    }

    .time {
      @include font14;
      color: var(--grey-color);
    }

    .close {
      position: absolute;
      top: -18px;
      right: -6px;
      opacity: 0.5;
      transition: 0.25s ease;

      &:hover {
        opacity: 1;
      }

      @media screen and (max-width: 767px) {
        top: -2px;
      }
    }

    .choose {
      @include contentButton;
      @include font14;
      padding: 6px 12px;
      margin: 12px auto 0 0;
      background-color: var(--primary-color);
    }

    @media screen and (max-width: 767px) {
      width: 267px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 12px 12px;
    width: 267px;
    min-height: 140px;
  }
}

.lro {
  path {
    fill: #e54f6d;
  }
}

.club {
  path {
    fill: #7464d1;
  }
}
