@import "/src/styles/mixin";

.lengthHint {
  @include font14;
  line-height: 24px !important;
  color: #c9ced6;
  font-weight: 500;
  position: absolute;
  right: 0;
  bottom: -4px;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 6px;

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    font-size: 12px !important;
  }
}
