.wrap {
  .items {
    display: grid;
    grid-template-columns: repeat(2, minmax(280px, 334px));
    gap: 12px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 690px) {
    width: calc(100vw - 60px);
  }
}
