@import "/src/styles/mixin";

.wrap {
  padding: 0 0 24px 0;
}

.title {
  @include font16Bold;
  margin-bottom: 12px;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  max-height: 500px;
  overflow-x: hidden;
  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    flex-direction: row;
    overflow-x: scroll;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.link {
  @include font14;
  @include lineHover;
  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    width: 100%;
    white-space: nowrap;
    border-radius: var(--border-radius-small);
    background-color: var(--blue-background-color);
    padding: 2px 8px;
  }
}
.text {
  @include font14;
  padding: 2px 12px;
  background-color: var(--grey-lines-color);
  text-align: left;
  border-radius: 100px;
}
