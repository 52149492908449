@import "/src/styles/mixin";

.input_wrap {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  background-color: var(--blue-background-color);
  border-radius: var(--border-radius-small);
  padding: 16px 16px;

  .input {
    width: 100%;
    cursor: text;
    transition: all 0.15s ease;

    &::placeholder {
      @include font14;
      color: var(--grey-third);
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    @include font14;
    @include dashedBottomLine;
    white-space: nowrap;
    color: var(--grey-third);
  }

  .button_icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}
