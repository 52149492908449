@import "/src/styles/mixin";

.card {
  &__point {
    display: flex;
    gap: 12px;
    &_content {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &_wrap {
      grid-area: 2 / 2 / 3 / 4;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    &_choosed {
      @include font14Bold;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        font-weight: 500;
        color: var(--grey-color);
      }
    }
    &_actions {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
    &_choose {
      @include font14;
      padding: 2px 8px;
      border-radius: var(--border-radius-small);
    }
    &_show {
      @include contentButtonTransparent;
      @include font14;
      padding: 2px 8px;
    }
  }
}
