@use "src/styles/mixins/ff" as *;
@import "/src/styles/mixin";

.counter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font14;
  padding: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  z-index: 1;
  transition: all 0.15s ease;

  .icon {
    width: 12px;
    height: 12px;
  }

  &Wrap {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    grid-area: 1 / 4 / 3 / 5;

    .piece_price {
      position: absolute;
      left: 50%;
      top: 36px;
      transform: translateX(-50%);
      width: max-content;
      @include ff(11px, 300, 16px);
      color: var(--grey-darker-color);
      white-space: none;

      @media screen and (max-width: 370px) {
        bottom: 26px;
      }
    }

    @media screen and (max-width: 767px) {
      grid-area: 1 / 4 / 3 / 5;
    }
  }

  &Input {
    @include font14;
    width: 44px;
    text-align: center;
  }

  .tooltip_active,
  .tooltip {
    position: absolute;
    min-width: 60px;
    top: calc(-100% - 11px);
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    transform: translate(-50%, 0);
    background-color: var(--primary-color);
    border-radius: 4px;
    padding: 4px;
    pointer-events: none;
    transition: all 0.25s ease-out;

    @include font14;
    color: var(--secondary-color);

    z-index: -1;

    &::after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid var(--primary-color);
      transition: all 0.15s ease;
      transition-delay: 0.35s;

      @media screen and (max-width: 767px) {
        left: 50%;
        transform: translate(26px, 0px);
      }
    }

    @media screen and (max-width: 768px) {
      left: 50%;
      transform: translate(-80%, 0);
    }
  }

  .tooltip {
    opacity: 0;
    transition: all 0.15s ease-out;
    transition-delay: 0.35s;

    &::after {
      bottom: 0px;
      transition: all 0.15s ease;
      transition-delay: 0s;
    }
  }

  &:disabled {
    opacity: 0.2;
    background-color: var(--blue-background-color);
    cursor: default;
  }
}

.notAvailable {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.append {
  position: relative;

  &::after {
    content: "";
  }
}
