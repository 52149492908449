@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding-bottom: 177px;
  padding-top: 30px;

  .title {
    width: 100%;
    height: 24px;
  }

  .search {
    width: 100%;
    height: 58px;
  }

  .content {
    display: flex;
    gap: 20px;

    .list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-right: 8px;
      width: 467px;
      height: 660px;
      max-height: 660px;
      overflow: auto;
      @include customScroll;

      @media screen and (max-width: 1024px) {
        width: 259px;
      }

      .department {
        width: 100%;
        height: 64px;
        min-height: 64px;
      }
    }

    .map {
      width: 100%;
      height: 660px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
