@import "/src/styles/mixin";

.title {
  @include font16Bold;
  position: relative;
  margin-bottom: 16px;
  color: var(--secondary-color);
  //font-family: "Gilroy", sans-serif;
}

.text {
  @include font14;
  color: var(--secondary-color);
  //font-family: "Gilroy", sans-serif;
}
