@import "../../../../../styles/mixin";

.wrap {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media screen and (max-width: 1365px) {
    max-width: 664px;
  }

  @media screen and (max-width: 1023px) {
    max-width: 418px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.loadMoreButton {
  @include contentButton;
  width: 135px;
  margin-left: 18px;

  &Text {
    padding: 12px 0;
    @include font14;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  gap: 46px;
  width: 318px;
  align-items: center;
  margin: 80px auto 0 auto;
  margin-left: auto;
  margin-top: 80px;

  &Picture {
    width: 143px;
    height: 128px;
    object-fit: contain;
  }

  &Text {
    @include font18;
    font-weight: 600 !important;
    line-height: 14px !important;
    color: #495057;
  }
}
