.cart_item {
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--grey-lines-color);
  margin-bottom: 12px;
  padding: 8px 0;
  gap: 10px;
  background-color: var(--white-color);

  .head {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .prices_wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;

      .custom_price {
        display: flex;
        flex-wrap: wrap;

        p {
          line-height: 16px;
        }
      }
    }

    .custom_photo {
      width: 120px;
    }

    .custom_counter {
      margin-left: auto;
    }
  }

  .name_wrap {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    align-items: flex-end;
  }
}
