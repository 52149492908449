@import "/src/styles/mixin";

.item {
  @include font14Bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    flex-direction: row;
    gap: 12px;
  }
}
.img {
  width: 60px;
  height: 60px;
  margin-bottom: 24px;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 0;
  }
}
