@import "/src/styles/mixin";

.title {
  @include font24;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;
  &Link {
    @include font14;
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
    font-weight: 500;
    color: var(--grey-color);
    &::before {
      content: "";
      width: 0;
      height: 2px;
      background-color: var(--grey-color);
      transition: width 0.2s ease;
    }
    &::after {
      content: "";
      width: 32px;
      height: 2px;
      background-color: var(--grey-color);
      transition: width 0.2s ease;
    }
    &:hover {
      &::before {
        width: 16px;
      }
      &::after {
        width: 16px;
      }
    }
  }
}
