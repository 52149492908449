.user_qr {
  width: 100%;
  max-width: 382px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 24px 24px;
  border: 1px solid var(--grey-lines-color);
  border-radius: var(--border-radius-normal);
  background-color: var(--white-color);
  gap: 12px;

  @media screen and (max-width: 1365px) {
    max-width: 468px;
  }

  .qrLink {
    text-align: center;
    background-color: var(--primary-color);
    padding: 12px 10px;
    border-radius: var(--border-radius-small);
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
    padding: 12px;
  }
}
