@import "/src/styles/mixin";

.uploader {
  margin-bottom: 32px;

  &Wrap {
    position: relative;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border: 1px dashed #c9ced6;
    border-radius: var(--border-radius-small);
  }

  &Output {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    max-width: 600px;
    overflow-y: hidden;
    border-radius: var(--border-radius-small);
    z-index: 1;

    &Image {
      width: 100px;
      height: 100px;
      object-fit: contain;

      &Wrap {
        position: relative;
        padding: 12px;
        border: 1px solid var(--black-color);
        border-radius: var(--border-radius-small);
      }

      &Remove {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
      }
    }
  }

  &Text {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    &Wrap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
      justify-content: center;
    }

    &Big {
      @include font16;
      font-weight: 400 !important;
      line-height: 16px;
      color: #9ea0a3;
    }

    &Small {
      @include font14;
      font-weight: 400 !important;
      line-height: 16px;
      color: #9ea0a3;
      display: inline;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        display: none;
      }
    }

    &Icon {
      height: 50px;
      width: 50px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        height: 26px;
        width: 30px;
      }
    }
  }

  &Hint {
    @include font14;
    margin-top: 8px;
    text-align: start;
    font-weight: 250 !important;
  }

  &Input {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
  }
}
