@import "/src/styles/mixin";

.wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  grid-area: 1 / 1 / 1 / 4;
  gap: 8px;
  .text {
    @include font14;

    &Wrap {
      margin-bottom: 8px;
      grid-area: 1 / 1 / 2 / 3;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-end;
  }

  .restore {
    @include font14;
    display: flex;
    align-items: center;
    gap: 8px;

    &Wrap {
      grid-area: 2 / 1 / 3 / 3;
    }
  }

  .close {
    // position: absolute;
    cursor: pointer;
    width: 30px;
    height: 30px;

    // bottom: 16px;
    // right: 0;
    .icon {
      g {
        path {
          fill: var(--grey-third);
        }
      }
    }

    &Wrap {
      grid-area: 1 / 3 / 2 / 4;
    }
  }
}
