@import "/src/styles/mixin";

.main {
  padding-bottom: 100px;

  .container {
    padding-top: 30px;
  }
}

.title {
  @include font30;
  margin-bottom: 30px;
}

.cart {
  &Wrap {
    display: flex;
    align-items: flex-start;
    gap: 60px;

    .no_price {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      transform: translateY(60px);

      .link {
        display: flex;
        justify-content: center;
        align-items: center;
        @include contentButton;
        gap: 6px;
        padding: 12px 24px;
        width: 100%;
        max-width: 320px;

        .icon {
          width: 24px;
          height: 24px;
        }

        @media screen and (max-width: 460px) {
          max-width: 100%;
          margin: 0 0;
        }
      }

      @media screen and (max-width: 767px) {
        transform: translateY(30px);
      }
    }

    @media only screen and (max-width: 1365.98px) {
      flex-direction: column;
    }

    @media only screen and (max-width: 767.98px) {
      gap: 32px;
    }
  }

  &Left {
    width: 860px;

    .select_all {
      padding-left: 8px;
    }

    @media only screen and (max-width: 1365.98px) {
      width: 100%;
    }

    .not_available {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .head {
        display: flex;
        flex-direction: row;
        gap: 12px;
        width: 100%;
        justify-content: space-between;
        margin-top: 48px;
        align-items: center;
        padding: 4px 24px;
        background-color: var(--blue-background-color);
        border-radius: 4px;

        .text {
          @include font18;
          letter-spacing: 0.01em;
          line-height: 32px;
          color: var(--red-0);
        }

        .removeall {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          @include font14;
          letter-spacing: 0.01em;
          font-weight: 600;

          &_icon {
            width: 10px;
            height: 10px;
          }
        }

        @media screen and (max-width: 767px) {
          padding: 4px 8px;
        }
      }
    }
  }

  aside {
    width: 100%;

    @media only screen and (max-width: 1365.98px) {
      width: 100%;
      max-width: 100% !important;
    }
  }
}

.itemsList {
  display: flex;
  flex-direction: column;
}
