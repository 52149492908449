@use "src/styles/mixins/ff" as *;

@import "/src/styles/mixin";

.wrap {
  @media screen and (max-width: 690px) {
    width: calc(100vw - 60px);
  }
}

.input {
  &List {
    display: grid;
    grid-template-columns: repeat(2, minmax(280px, 334px));
    gap: 12px;
    margin-bottom: 24px;

    @media screen and (max-width: 625px) {
      display: flex;
      flex-direction: column;
    }

    .select {
      border: 1px solid var(--grey-lines-color);
      border-radius: var(--border-radius-small);
      transition: 0.25s;
    }

    .select:hover {
      border-color: var(--black-color);
    }
  }
}

.input:invalid {
  border-color: var(--red-color);
}

.button {
  padding: 14px 26px;
}

.rules {
  @include ff(14, 400, 22px);
  display: flex;
  align-items: center;
  text-decoration: underline;
  gap: 4px;
  margin-top: 12px;

  .icon {
    width: 18px;
    height: 18px;
  }
}

.textarea {
  @include font14;
  width: 100%;
  padding: 14px 20px;
  border: 1px solid var(--grey-lines-color);
  border-radius: var(--border-radius-small);
  resize: none;
  transition: border-color 0.2s ease;

  &:focus,
  &:hover {
    border-color: var(--black-color);
  }

  &Wrap {
    margin-bottom: 24px;
    position: relative;
    width: 100%;
  }
}
