.wrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloaderWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: var(--black-color) var(--black-color) transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent var(--primary-color) var(--primary-color);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  &.mini {
    width: 20px;
    height: 20px;
    border-width: 2px;
    &::after {
      width: 14px;
      height: 14px;
      border-width: 2px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 40px;
    height: 40px;
    &::after {
      width: 35px;
      height: 35px;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
