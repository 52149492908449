@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    gap: 24px;
  }

  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 320px) and (max-width: 767px) {
      flex-direction: column-reverse;
      justify-content: flex-start;
      gap: 16px;
    }

    &Reverse {
      flex-direction: row-reverse;

      @media screen and (min-width: 320px) and (max-width: 767px) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        gap: 16px;
      }
    }

    &TextFirst {
      flex-direction: column;
    }

    .textWrap {
      max-width: 487px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media screen and (max-width: 1365px) {
        max-width: 374px;
      }

      @media screen and (max-width: 1023px) {
        max-width: 320px;
      }

      @media screen and (min-width: 320px) and (max-width: 767px) {
        gap: 10px;
        max-width: 100%;
      }

      &Wide {
        max-width: 100%;
      }

      .title {
        @include font24;
        font-weight: 600;
        line-height: 28px;
        margin: 0;

        @media screen and (min-width: 320px) and (max-width: 767px) {
          line-height: 24px;
        }
      }

      .text {
        @include font16;
        font-weight: 500;
        line-height: 28px;
      }
    }

    .img {
      width: 100%;
      max-width: 487px;
      height: 487px;

      @media screen and (max-width: 1365px) {
        max-width: 400px;
        height: 400px;
      }

      @media screen and (max-width: 1023px) {
        max-width: 320px;
        height: 320px;
      }

      @media screen and (min-width: 320px) and (max-width: 767px) {
        max-width: 100%;
        object-fit: cover;
      }
    }
  }
}
