.cardsList {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 12px;
  margin-bottom: 32px;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 16px;
  }
}

.card {
  max-width: unset !important;
  width: 23.9%;
  @media only screen and (min-width: 1366px) and (max-width: 1439.98px) {
    width: 32%;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
    width: 23.9%;
  }
  @media only screen and (min-width: 600px) and (max-width: 1023.98px) {
    width: 31.9%;
  }
  @media screen and (max-width: 599.98px) {
    width: 47.9%;
  }
}
