@import "/src/styles/mixin";

.action {
  &Wrap {
    display: flex;
    justify-content: flex-end;
    grid-area: 3 / 4 / 4 / 5;
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      grid-area: 4 / 3 / 5 / 5;
    }
  }
}
