@import "/src/styles/mixin";

.item {
  width: 370px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media only screen and (min-width: 768px) and (max-width: 1365.98px) {
    width: 540px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 100%;
  }
}

.title {
  @include font20;
  margin-bottom: 12px;
}

.text {
  @include font14;
  margin-bottom: 24px;
}

.link {
  @include contentButton;
  @include font14;
  margin-top: auto;
  padding: 8px 20px;
}

.img {
  margin-bottom: 20px;
  height: 70px;
  width: 70px;
}
