@use "src/styles/mixins/ff" as *;
@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  grid-area: 1 / 3 / 3 / 4;
  margin-right: 30px;

  .price {
    @include ff(18px, 600, 24px);
    white-space: nowrap;
    @media screen and (max-width: 425px) {
      line-height: 24px;
    }
  }

  .old {
    @include ff(14px, 300, 28px);
    text-decoration: line-through;
    color: var(--grey-darker-color);
    white-space: nowrap;

    @media screen and (max-width: 425px) {
      line-height: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    grid-area: 1 / 2 / 2 / 4;
    flex-direction: column-reverse;
    margin-right: 0;
  }
}
