@import "/src/styles/mixin";

.wrap {
  position: relative;
  margin-right: 8px;
  &:hover {
    .reviewsWrap {
      visibility: visible;
      opacity: 1;
    }
  }
}

.link {
  display: flex;
  gap: 8px;
  align-items: center;
}

.text {
  @include font14;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    @include font12;
    font-weight: 500;
  }
}

.reviewsWrap {
  position: absolute;
  top: 20px;
  left: 40px;
  width: 345px;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0.2s ease,
    opacity 0.2s ease;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: none;
  }
}
.triangle {
  margin-left: 20px;
  width: 45px;
  height: 24px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5);
  &:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: var(--white-color);
    transform: rotate(45deg);
    top: 12px;
    left: 8px;
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: none;
  }
}
