@use "src/styles/mixins/ff" as *;
@import "src/styles/mixin";

.city {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  .label {
    color: var(--secondary-color);

    &.lg {
      @include ff(18px, 600, 24px);
    }

    &.sm {
      @include ff(14px, 400, 16px);
    }

    &.md {
      @include ff(14px, 400, 24px);
    }
  }

  .button {
    @include ff(18px, 700, 24px);
    @include dashedBottomLine;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.lg {
      @include ff(18px, 600, 24px);
      max-width: 200px;
    }

    &.sm {
      @include ff(14px, 400, 16px);
      max-width: 100px;
    }

    &.md {
      @include ff(14px, 400, 24px);
      max-width: 130px;
    }
  }

  .tooltip {
    position: absolute;
    top: 50%;
    left: -60%;
    transform: translateY(-50%);
    max-width: 250px;
    background-color: var(--bar-3);
    border-radius: var(--border-radius-small);
    transition: all 0.25s ease;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -12px;
      width: 24px;
      height: 24px;
      background-color: var(--bar-3);
      clip-path: polygon(0 0, 100% 50%, 0 100%);
      transform: translateY(-50%);
      border-top-right-radius: 6px;

      @media screen and (max-width: 1365px) {
        top: unset;
        right: unset;
        bottom: -12px;
        left: 80%;
        transform: translateX(-50%) rotate(-30deg);
      }
    }

    .content {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 30px 24px;

      .close {
        position: absolute;
        top: 8px;
        right: 8px;
      }

      .text {
        @include ff(18px, 700, 24px);
      }
    }

    @media screen and (max-width: 1559px) {
      left: -90%;
    }

    @media screen and (max-width: 1365px) {
      top: -100%;
      right: -10px;
      left: unset;
      transform: translateY(-100%);
    }
  }
}

.hintText {
  @include ff(18px, 700, 24px);
  box-sizing: content-box;
  width: 100%;
  max-width: 202px;
  padding: 10px 4px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  .text {
    @include ff(18px, 400, 24px);
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    width: 100%;

    .button {
      padding: 8px 24px;
      @include contentButton;
    }
  }
}
