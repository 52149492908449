@import "/src/styles/mixin";

.wrap {
  position: relative;
  padding: 16px 0;
  display: grid;
  grid-template-columns: 166px 1fr 100px 100px;
  grid-template-rows: 36px 1fr 24px;
  border-bottom: 1px solid var(--grey-lines-color);
  background-color: var(--white-color);

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 12px 0;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 32px auto 1fr 32px;
  }

  img {
    cursor: pointer;
  }
}

.removed {
  padding: 16px 24px;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, auto);
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 16px 30px 16px 0;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }
}

.isNotAvailable {
  order: 1;
}
