@import "src/styles/mixin";

.wrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100svh - 118px);

  .form {
    display: flex;
    width: 100%;
    max-width: 320px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 20px;
    background-color: var(--grey-secondary);
    border-radius: var(--border-radius-medium);

    .input {
      width: 100%;
    }

    button {
      @include contentButton;
      padding: 8px 12px;
      width: 100%;
    }
  }
}
