@import "/src/styles/mixin";

.store {
  display: flex;
  gap: 12px;
  padding-bottom: 16px;
  &Info {
    width: 100%;
  }
  &:not(:last-child) {
    border-bottom: 1px dashed var(--grey-lines-color);
  }
}
.icon {
  width: 20px;
  height: 24px;
}

.address {
  @include font14;
  padding-right: 16px;
  font-style: normal;
  span {
    @include font14;
    display: block;
    color: var(--grey-darker-color);
  }
}
