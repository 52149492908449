.goldWarranty {
  display: block;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--blue-color);
  padding: 4px 20px;
  svg {
    width: 76px;
    height: 39px;
  }
}
