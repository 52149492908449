.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  grid-area: 1 / 1 / 4 / 2;

  .container {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;

    .photo {
      height: 100%;
      mix-blend-mode: multiply;
      aspect-ratio: 1;
      object-fit: contain;
      z-index: 1;
    }

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 200px;
      height: 200px;
      z-index: 2;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    grid-area: 1 / 1 / 3 / 2;
    padding: 0 0;
  }
  @media screen and (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.container {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-small);
  //background-color: var(--blue-background-color);
  aspect-ratio: 1;
}

.notAvailable {
  opacity: 0.5;
}

.available {
  opacity: 1;
}
