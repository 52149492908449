@import "/src/styles/mixin";

.wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 400px;
  text-align: center;

  .title {
    @include font24;
    z-index: 2;
    line-height: 24px;
  }

  .text {
    @include font16;
    line-height: 16px;
    font-weight: 400;
    z-index: 2;
    margin-bottom: 12px;
  }

  .image {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%) rotate(-180deg);
    object-fit: contain;
    width: auto;
    height: 100%;
    aspect-ratio: 1 / 1;
    z-index: 1;
    opacity: 0.6;
  }

  .link {
    @include font16;
    font-weight: 400;
    padding: 6px 12px;
    background-color: var(--primary-color);
    border-radius: var(--border-radius-small);
    transition: all 0.15s ease;
    z-index: 2;

    &:hover {
      background-color: var(--hover-primary-color);
    }
  }
}
