.wrap {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;
    gap: 60px;
    position: relative;

    @media screen and (max-width: 1366px) {
      flex-direction: column;
      gap: 36px;
    }

    .price {
      position: sticky;
      top: 140px;
      display: flex;
      flex-direction: column;
      max-width: 520px;
      width: 100%;

      .price_block {
        max-width: 520px;

        @media screen and (max-width: 1366px) {
          max-width: 100%;
          order: 2;
        }
      }

      @media screen and (max-width: 1366px) {
        max-width: 100%;
        margin-bottom: 100px;
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
