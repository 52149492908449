@import "/src/styles/mixin";

.popup {
  width: 100%;
  max-width: 403px;
}

.container {
  padding: 20px 0 !important;
}

.list {
  &Title {
    @include font14;
    line-height: 14px !important;
    font-weight: 600;
  }

  &Item {
    @include font14;
    line-height: 32px !important;
    font-weight: 500;
    display: flex;
    align-items: flex-start;

    &::before {
      content: ".";
      height: 4px;
      margin-right: 8px;
      margin-top: 2px;
      font-size: 30px;
      line-height: 4px;
      color: #c9ced6;
    }

    @media screen and (min-width: 320px) and (max-width: 767px) {
      line-height: 24px !important;
    }
  }
}
