@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-width: 300px;
  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    max-width: unset;
    width: 100%;
  }
}

.title {
  @include font18;
  margin-bottom: 16px;
}

.link {
  @include font14;
  text-decoration: underline;
}

.characteristics_link {
  @include font14;
  color: var(--blue-color);
}

.prop {
  &__container {
    width: 300px;
    @media only screen and (min-width: 768px) and (max-width: 1365.98px) {
      width: 100%;
    }
  }
}
