@import "/src/styles/mixin";

.title {
  @include font30;
  max-width: 1000px;
  &Wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      margin-bottom: 4px;
    }
    .date {
      @include font14;
      letter-spacing: 0.01em;
      color: var(--grey-light-color);
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    @include font16Bold;
  }
}

.comission {
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.wrap {
  width: 100%;
}
