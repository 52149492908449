@use "src/styles/mixins/ff" as *;
@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-right: 80px;
  grid-area: 1 / 2 / 4 / 3;

  .name {
    @include font14;
    line-height: 28px;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .code {
    @include font14;
    line-height: 28px;
    color: var(--grey-color);
  }

  .available_now {
    @include ff(14px, 400, 28px);
    color: var(--secondary-color);

    .span {
      color: var(--green-secondary);
    }
  }

  @media screen and (max-width: 767px) {
    margin-right: 0;
    grid-area: 3 / 1 / 4 / 5;
  }
}
