@import "src/styles/mixin";

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000003;
  background-color: rgba(29, 29, 29, 0.5);
  .alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 20px);
    max-width: 673px;

    &_container {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 30px 40px 20px;
      background-color: var(--white-color);
      text-align: center;
      border-radius: 20px;

      .link {
        margin: 0 4px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      &_text {
        @include font14Bold;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: center;

        .title {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          letter-spacing: 0.01em;
          margin-bottom: 12px;
        }

        @media screen and (max-width: 460px) {
          font-size: 14px;
        }
      }

      .close {
        position: absolute;
        top: 12px;
        right: 12px;
      }

      .accept {
        @include font14Bold;
        @include contentButton;
        margin: 0 auto;
        padding: 10px 56px;
        max-width: 169px;
      }

      @media screen and (max-width: 460px) {
        padding: 20px 20px 20px;
      }
    }
  }
}
