.card {
  width: 100%;
  &Info {
    display: flex;
    gap: 60px;
    justify-content: space-between;
    &Left {
      position: sticky;
      top: 140px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 60px;
      @media only screen and (min-width: 320px) and (max-width: 1560px) {
        gap: 20px;
      }
      @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
        flex-direction: column;
        align-items: stretch;
      }
      @media only screen and (min-width: 320px) and (max-width: 767.98px) {
        position: unset;
        gap: unset;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
      gap: 20px;
    }
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      flex-direction: column;
    }
  }
}
.titleBotWrap {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    flex-wrap: wrap;
  }
}
