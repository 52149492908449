.social {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 12px 24px;
  border: 1px solid var(--grey-lines-color);
  border-radius: var(--border-radius-normal);
  background-color: var(--white-color);
  gap: 12px;
  max-width: 468px;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
}
