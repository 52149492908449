@import "/src/styles/mixin";

.promocode {
  position: relative;
  @include contentInput;
  width: 100%;
  padding: 16px 80px 16px 16px;
  background-color: var(--white-color);

  &Wrap {
    position: relative;
    z-index: 0;

    .disable {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--grey-lines-color);
      border-radius: var(--border-radius-small);
      opacity: 0.5;
      user-select: none;
      z-index: 10;
    }
  }

  &Button {
    @include font14;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
    color: var(--secondary-color);
    transition: color 0.2s ease;
  }
}
