@import "/src/styles/mixin";

.description {
  @include font14;
  width: 100%;
  max-width: 1075px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  position: relative;
  gap: 20px;

  p,
  a,
  span,
  li {
    @include font14;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  ul {
    padding-left: 20px;
  }

  li {
    list-style-type: circle;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  .shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 33px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 11.28%, #ffffff 61.32%);
    transition: all 5s ease-in-out;

    &_hiddent {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 33px;
      background: rgb(255, 255, 255, 0);
      transition: all 5s ease-in-out;
    }
  }
}

.descriptionText {
  max-width: 885px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  //max-height: 250px;

  &_content {
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}

.show_all {
  position: relative;
  color: var(--blue-color);
  padding-bottom: 22px;
  margin-top: 10px;
  font-weight: 700;
  text-align: left;
  width: 100%;
  background-color: var(--white-color);
  z-index: 2;
}
