.item {
  width: 223px;
  aspect-ratio: 1;
}

.link {
  display: block;
  width: 223px;
  @media screen and (max-width: 768px) {
    width: 134px;
  }
}

.img {
  aspect-ratio: 1;
  height: unset;
}
