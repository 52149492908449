@import "/src/styles/mixin";

.list {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.item {
  position: relative;
  width: 42px;
  height: 42px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--secondary-color);
  cursor: pointer;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
